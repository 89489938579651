<nav
  class="navbar transition-all fixed top-0 z-50 w-full flex flex-wrap items-center justify-between navbar navbar-expand-lg"
  [ngClass]="scrolledDown ? 'navbar--filled' : 'navbar--transparent'"
  data-te-navbar-ref
>
  <div class="flex w-full flex-wrap items-center justify-between px-3">
    <button
      class="navbar-toggler w-6 h-6 lg:hidden"
      [class.expanded]="isExpanded()"
      type="button"
      id="navbarCollapseButton"
      data-te-collapse-init
      data-te-target="#navbarSupportedContent1"
      aria-controls="navbarSupportedContent1"
      aria-expanded="false"
      aria-label="Toggle navigation"
      #collapseButton
    >
      <div class="flex justify-center items-center text-2xl">
        <fa-icon class="expand-icon absolute transition-opacity" [icon]="expandIcon"></fa-icon>
        <fa-icon class="collapse-icon absolute transition-opacity" [icon]="collapseIcon"></fa-icon>
      </div>
    </button>

    <div
      class="!visible hidden flex-grow basis-[100%] items-center lg:!flex lg:basis-auto"
      id="navbarSupportedContent1"
      data-te-collapse-item
    >
      <div class="flex flex-col justify-end items-start lg:items-end md:flex-row md:items-center w-full">
        <ul class="navbar-nav flex flex-col md:flex-row pl-0 list-style-none text-right">
          <li class="nav-item">
            <a routerLink="/">
              <a-button
                class="min-w-[150px]"
                routerLinkActive="text-primary"
                color="transparent"
                [routerLinkActiveOptions]="{ exact: true }"
                ><span class="title text-xl">Home</span></a-button
              >
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/leaderboards">
              <a-button class="min-w-[150px]" routerLinkActive="text-primary" color="transparent"
                ><span class="title text-xl">Leaderboards</span></a-button
              >
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/blog/roadmap">
              <a-button class="min-w-[150px]" routerLinkActive="text-primary" color="transparent"
                ><span class="title text-xl">Roadmap</span></a-button
              >
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/blog">
              <a-button class="min-w-[150px]" routerLinkActive="text-primary" color="transparent"
                ><span class="title text-xl">Blog</span></a-button
              >
            </a>
          </li>
          <li class="nav-item">
            <a href="https://store.steampowered.com/app/2179440/New_Heights" target="_blank">
              <a-button
                class="min-w-[150px] !animation-delay-2000"
                routerLinkActive="text-primary"
                [color]="scrolledDown ? 'focus' : 'transparent'"
                [class.animate-shake]="scrolledDown"
                ><span class="title text-xl">Buy now</span></a-button
              >
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
