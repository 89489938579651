import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'beta',
    loadChildren: () => import('./pages/demo/demo.module').then((m) => m.DemoModule),
  },
  {
    path: 'blog',
    loadChildren: () => import('./pages/blog/blog.module').then((m) => m.BlogModule),
  },
  {
    path: 'leaderboards',
    loadChildren: () => import('./pages/leaderboard/leaderboard.module').then((m) => m.LeaderboardModule),
  },
];
