import { bootstrapApplication } from '@angular/platform-browser';
import AOS from 'aos';
import { Collapse, Dropdown, initTE, Ripple } from 'tw-elements';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));

initTE({ Ripple, Dropdown, Collapse });

AOS.init();
