import { CommonModule, DOCUMENT, isPlatformBrowser, Location, NgOptimizedImage } from '@angular/common';
import {
  AfterContentChecked,
  Component,
  ElementRef,
  HostListener,
  Inject,
  LOCALE_ID,
  OnInit,
  PLATFORM_ID,
  signal,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBars, faMinus } from '@fortawesome/free-solid-svg-icons';
import { ButtonComponent } from '../../atoms/button/button.component';

@Component({
  selector: 'o-navbar',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, ButtonComponent, RouterModule, NgOptimizedImage],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, AfterContentChecked {
  scrolledDown = false;
  expandIcon = faBars;
  collapseIcon = faMinus;
  isExpanded = signal(false);

  @ViewChild('navbar') navbar: ElementRef;
  @ViewChild('collapseButton') collapseButton: ElementRef<HTMLButtonElement>;

  constructor(
    @Inject(Location) public readonly location: Location,
    @Inject(LOCALE_ID) public readonly localeId: string,
    @Inject(PLATFORM_ID) private readonly platformId: object,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly router: Router
  ) {}

  ngAfterContentChecked(): void {
    this.isExpanded.update(() => this.collapseButton?.nativeElement.getAttribute('aria-expanded') === 'true');
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.updateScrolledDownPosition();
    }

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (this.isExpanded()) {
          this.collapseButton.nativeElement.click();
        }
      }
    });
  }

  @HostListener('window:scroll', ['$event']) onScroll() {
    this.updateScrolledDownPosition();
  }

  updateScrolledDownPosition() {
    const threshold = 0;
    this.scrolledDown = window.scrollY > threshold || window.pageYOffset > threshold;
  }

  scroll(query: string) {
    const el = this.document.querySelector(query);
    el.scrollIntoView({ behavior: 'smooth' });
  }
}
