<footer class="relative w-full bg-dark">
  <div class="flex flex-col md:flex-row gap-5 justify-between container py-5 text-light-dark">
    <div class="flex items-center justify-center md:justify-start">
      <a class="h-16 aspect-square relative mr-5" routerLink="/">
        <img ngSrc="assets/img/new-heights-logo.svg" alt="New Heights: Realistic Climbing & Bouldering Logo" fill />
      </a>
      <div>
        <a class="title text-3xl text-white" routerLink="/">New Heights</a>
        <p>Realistic Climbing & Bouldering</p>
        <p>
          By
          <a class="link" href="https://wikkl.me/en/" target="_blank">Wikkl Works.</a>
        </p>
      </div>
    </div>
    <div
      class="md:ml-auto flex gap-5 md:gap-16 whitespace-nowrap justify-around md:justify-start text-center md:text-left">
      <div class="flex flex-col w-32 md:w-fit">
        <!-- <a class="link" routerLink="/demo">Download Demo</a> -->
        <a class="link" routerLink="/beta">Access the beta</a>
      </div>
      <div class="flex flex-col w-32 md:w-fit">
        <a class="link" href="https://store.steampowered.com/app/2179440/New_Heights" target="_blank">Steam</a>
        <a class="link" href="https://discord.gg/2DqPft9tnk" target="_blank">Discord</a>
      </div>
      <div class="flex flex-col w-32 md:w-fit">
        <a class="link" href="mailto:info@newheightsgame.com" target="_blank">Contact</a>
        <a class="link" href="https://wikkl.me/en/" target="_blank">About us</a>
        <a class="link" href="https://newheightsgame.com/presskit/" target="_blank">Presskit</a>
        <a class="link" routerLink="/blog">Blog</a>
      </div>
    </div>
    <a href="https://wikkl.me/en/" target="_blank" class="h-24 aspect-square relative">
      <img ngSrc="assets/img/wikkl-logo-text.svg" alt="Wikkl Logo" fill />
    </a>
  </div>
</footer>