import { ApplicationConfig } from '@angular/core';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';

import { provideHttpClient } from '@angular/common/http';
import { provideClientHydration } from '@angular/platform-browser';
import { provideMarkdown } from 'ngx-markdown';
import { HtmlExcerptPipe } from '../wikkllibrary-angular/pipes/html-excerpt.pipe';
import { HtmlToTextPipe } from '../wikkllibrary-angular/pipes/html-to-text.pipe';
import { MdExcerptPipe } from '../wikkllibrary-angular/pipes/md-excerpt.pipe';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withEnabledBlockingInitialNavigation()),
    provideClientHydration(),
    provideHttpClient(),
    provideMarkdown(),
    HtmlToTextPipe,
    MdExcerptPipe,
    HtmlExcerptPipe,
  ],
};
